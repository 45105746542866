@font-face {
  font-family: "orbithc";
  src: url("fonts/orbithc.eot?qu2dd4");
  src: url("fonts/orbithc.eot?qu2dd4#iefix") format("embedded-opentype"),
    url("fonts/orbithc.ttf?qu2dd4") format("truetype"),
    url("fonts/orbithc.woff?qu2dd4") format("woff"),
    url("fonts/orbithc.svg?qu2dd4#orbithc") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="oh-"],
[class*=" oh-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "orbithc" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 20px;
  padding: 5px 10px;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.oh-pageDown:before {
  content: "\ea44";
}
.oh-crop:before {
  content: "\ea43";
}
.oh-postalmail:before {
  content: "\ea3c";
}
.oh-megaphone_settings:before {
  content: "\ea36";
}
.oh-capturedface:before {
  content: "\ea32";
}
.oh-WHO:before {
  content: "\ea18";
}
.oh-WHC:before {
  content: "\ea19";
}
.oh-SWH:before {
  content: "\ea1a";
}
.oh-SmallMenu:before {
  content: "\e9ff";
}
.oh-Pen:before {
  content: "\e9ba";
}
.oh-Pencil:before {
  content: "\e9ba";
}
.oh-changesm:before {
  content: "\e9bb";
}
.oh-clear2:before {
  content: "\e9bc";
}
.oh-clock:before {
  content: "\e9bd";
}
.oh-cross:before {
  content: "\e9be";
}
.oh-deletesm:before {
  content: "\e9bf";
}
.oh-release:before {
  content: "\e9c0";
}
.oh-releasesm:before {
  content: "\e9c1";
}
.oh-unlock:before {
  content: "\e9c1";
}
.oh-crop2:before {
  content: "\ea42";
}
.oh-ocr:before {
  content: "\ea3d";
}
.oh-Image:before {
  content: "\ea41";
}
.oh-Img-crop:before {
  content: "\ea3e";
}
.oh-Compression:before {
  content: "\ea3f";
}
.oh-Dimensions:before {
  content: "\ea40";
}
.oh-view-close:before {
  content: "\ea3b";
}
.oh-eye-close:before {
  content: "\ea3b";
}
.oh-AddRecord:before {
  content: "\ea3a";
}
.oh-rotate-left:before {
  content: "\ea38";
}
.oh-rotate-right:before {
  content: "\ea39";
}
.oh-mips:before {
  content: "\ea37";
}
.oh-Megaphone:before {
  content: "\ea33";
}
.oh-Comment:before {
  content: "\ea34";
}
.oh-ListMenu:before {
  content: "\ea35";
}
.oh-facecapture:before {
  content: "\ea31";
}
.oh-home:before {
  content: "\ea30";
}
.oh-MBMenu:before {
  content: "\ea2f";
}
.oh-MergeDOC2:before {
  content: "\ea2c";
}
.oh-MergeDOC:before {
  content: "\ea2d";
}
.oh-SplitDOC:before {
  content: "\ea2e";
}
.oh-Reassign:before {
  content: "\ea28";
}
.oh-DismissAlert:before {
  content: "\ea29";
}
.oh-Snooze:before {
  content: "\ea2a";
}
.oh-ESL:before {
  content: "\ea27";
}
.oh-Preauthorization:before {
  content: "\ea1b";
}
.oh-Expense:before {
  content: "\ea15";
}
.oh-Expense2:before {
  content: "\ea14";
}
.oh-HealthRecord:before {
  content: "\ea12";
}
.oh-Health:before {
  content: "\ea11";
}
.oh-HealthNew:before {
  content: "\ea10";
}
.oh-VSR:before {
  content: "\ea13";
}
.oh-EDS:before {
  content: "\ea0d";
}
.oh-STS:before {
  content: "\ea0e";
}
.oh-DateSelect:before {
  content: "\ea16";
}
.oh-Bell:before {
  content: "\ea17";
}
.oh-EDS-old:before {
  content: "\ea0b";
}
.oh-STS-old:before {
  content: "\ea0c";
}
.oh-TimeCardLog:before {
  content: "\ea08";
}
.oh-TimeCard:before {
  content: "\ea09";
}
.oh-TimeManagement:before {
  content: "\ea0a";
}
.oh-university:before {
  content: "\e9d2";
}
.oh-trip:before {
  content: "\e9cc";
}
.oh-tripsummary:before {
  content: "\e9cd";
}
.oh-tripsche:before {
  content: "\e9ce";
}
.oh-tripq:before {
  content: "\e9cf";
}
.oh-tripressche:before {
  content: "\e9d0";
}
.oh-tripresman:before {
  content: "\e9d1";
}
.oh-ambulance:before {
  content: "\e9cb";
}
.oh-tiles:before {
  content: "\e9e3";
}
.oh-company:before {
  content: "\e900";
}
.oh-visitcancel:before {
  content: "\e901";
}
.oh-CVA:before {
  content: "\e901";
}
.oh-services:before {
  content: "\e902";
}
.oh-item2:before {
  content: "\e902";
}
.oh-crm:before {
  content: "\e903";
}
.oh-headset:before {
  content: "\e903";
}
.oh-item:before {
  content: "\e904";
}
.oh-business:before {
  content: "\e905";
}
.oh-shipping:before {
  content: "\e906";
}
.oh-inventory:before {
  content: "\e907";
}
.oh-scheduler:before {
  content: "\e908";
}
.oh-appointment:before {
  content: "\e909";
}
.oh-SFA:before {
  content: "\e909";
}
.oh-patient:before {
  content: "\e90a";
}
.oh-customer:before {
  content: "\e90b";
}
.oh-lead:before {
  content: "\e90c";
}
.oh-leadsummary:before {
  content: "\e90d";
}
.oh-customers:before {
  content: "\e90e";
}
.oh-order:before {
  content: "\e90f";
}
.oh-ordersummary:before {
  content: "\e910";
}
.oh-photo:before {
  content: "\e911";
}
.oh-undefined:before {
  content: "\e912";
}
.oh-billing:before {
  content: "\e913";
}
.oh-reports:before {
  content: "\e914";
}
.oh-financials:before {
  content: "\e915";
}
.oh-administration:before {
  content: "\e916";
}
.oh-userapps:before {
  content: "\e917";
}
.oh-settings:before {
  content: "\e918";
}
.oh-appsettings:before {
  content: "\e919";
}
.oh-datalib:before {
  content: "\e91a";
}
.oh-datarecord:before {
  content: "\e91b";
}
.oh-marketing:before {
  content: "\e91c";
}
.oh-sales:before {
  content: "\e91d";
}
.oh-smartship:before {
  content: "\e91e";
}
.oh-invoice:before {
  content: "\e91f";
}
.oh-invoicing:before {
  content: "\e920";
}
.oh-payment:before {
  content: "\e921";
}
.oh-claim:before {
  content: "\e922";
}
.oh-claimsummary:before {
  content: "\e923";
}
.oh-paymentplan:before {
  content: "\e924";
}
.oh-paymentsummary:before {
  content: "\e925";
}
.oh-paymentactivity:before {
  content: "\e926";
}
.oh-doctor:before {
  content: "\e927";
}
.oh-visitsummary:before {
  content: "\e928";
}
.oh-visitq:before {
  content: "\e929";
}
.oh-contact:before {
  content: "\e92a";
}
.oh-practice:before {
  content: "\e92b";
}
.oh-businesspartner:before {
  content: "\e92c";
}
.oh-syssetting:before {
  content: "\e92d";
}
.oh-resource:before {
  content: "\e92e";
}
.oh-serviceshospital:before {
  content: "\e92f";
}
.oh-feeschedule:before {
  content: "\e930";
}
.oh-payercontract:before {
  content: "\e931";
}
.oh-resourcescheduler:before {
  content: "\e932";
}
.oh-support2:before {
  content: "\e933";
}
.oh-support:before {
  content: "\e933";
}
.oh-messaging:before {
  content: "\e934";
}
.oh-mail:before {
  content: "\e934";
}
.oh-preference:before {
  content: "\e935";
}
.oh-accountmanagement:before {
  content: "\e936";
}
.oh-mytask:before {
  content: "\e937";
}
.oh-resourceman:before {
  content: "\e938";
}
.oh-asset:before {
  content: "\e939";
}
.oh-rental1:before {
  content: "\e93a";
}
.oh-rental:before {
  content: "\e93a";
}
.oh-showguide:before {
  content: "\e93b";
}
.oh-auditlog:before {
  content: "\e93c";
}
.oh-errorlog:before {
  content: "\e93d";
}
.oh-CPTEM:before {
  content: "\e93e";
}
.oh-procedureHCPCS:before {
  content: "\e93f";
}
.oh-modifiercode:before {
  content: "\e940";
}
.oh-remittanceadvice:before {
  content: "\e941";
}
.oh-eligibilityreq:before {
  content: "\e942";
}
.oh-claimstatus:before {
  content: "\e943";
}
.oh-claimsubmissions:before {
  content: "\e944";
}
.oh-processq:before {
  content: "\e945";
}
.oh-locationgrp:before {
  content: "\e946";
}
.oh-partnersgrp:before {
  content: "\e947";
}
.oh-access:before {
  content: "\e948";
}
.oh-holiday:before {
  content: "\e949";
}
.oh-calendar:before {
  content: "\e949";
}
.oh-dayshours:before {
  content: "\e94a";
}
.oh-workQ:before {
  content: "\e94b";
}
.oh-RecNoSch:before {
  content: "\e94c";
}
.oh-book:before {
  content: "\e94d";
}
.oh-diagnosis:before {
  content: "\e94e";
}
.oh-Internal:before {
  content: "\e94f";
}
.oh-lock_line:before {
  content: "\ea2b";
}
.oh-AppointmentsList:before {
  content: "\ea26";
}
.oh-BookVideo:before {
  content: "\ea23";
}
.oh-BookHelp:before {
  content: "\ea24";
}
.oh-EduHelp:before {
  content: "\ea25";
}
.oh-VideoHelp:before {
  content: "\ea21";
}
.oh-showguiden:before {
  content: "\ea22";
}
.oh-DP3:before {
  content: "\ea1e";
}
.oh-DP2:before {
  content: "\ea1f";
}
.oh-DP1:before {
  content: "\ea20";
}
.oh-OverBooked:before {
  content: "\ea1c";
}
.oh-NoSchedule:before {
  content: "\ea1d";
}
.oh-recurrence:before {
  content: "\ea0f";
}
.oh-PopOut:before {
  content: "\ea05";
}
.oh-PullUp:before {
  content: "\ea06";
}
.oh-PullDown:before {
  content: "\ea07";
}
.oh-ProcStop:before {
  content: "\ea00";
}
.oh-ProcStart:before {
  content: "\ea04";
}
.oh-DocPack:before {
  content: "\ea03";
}
.oh-Indexq:before {
  content: "\ea01";
}
.oh-IndexingQ:before {
  content: "\ea02";
}
.oh-DocAudit:before {
  content: "\e9fa";
}
.oh-DocOutLog:before {
  content: "\e9fb";
}
.oh-FldrPack:before {
  content: "\e9fc";
}
.oh-DocSmry:before {
  content: "\e9fd";
}
.oh-DymcSmry:before {
  content: "\e9fe";
}
.oh-workqsummary:before {
  content: "\e9f9";
}
.oh-excel:before {
  content: "\e9f3";
}
.oh-CRC:before {
  content: "\e9f7";
}
.oh-CustRpt:before {
  content: "\e9f8";
}
.oh-docs:before {
  content: "\e9f4";
}
.oh-SharedPF:before {
  content: "\e9f5";
}
.oh-monthend:before {
  content: "\e9f6";
}
.oh-dataexport:before {
  content: "\e9ec";
}
.oh-dataimport:before {
  content: "\e9ed";
}
.oh-smartforms:before {
  content: "\e9ee";
}
.oh-pecos:before {
  content: "\e9ef";
}
.oh-formfields:before {
  content: "\e9f0";
}
.oh-template:before {
  content: "\e9f1";
}
.oh-LOVSettings:before {
  content: "\e9f2";
}
.oh-redirect:before {
  content: "\e9eb";
}
.oh-rightarrowoutline:before {
  content: "\e9ea";
}
.oh-textclear:before {
  content: "\e9e9";
}
.oh-saveall:before {
  content: "\e9e8";
}
.oh-rightarrowsolid:before {
  content: "\e9e7";
}
.oh-infon:before {
  content: "\e9e6";
}
.oh-errorn:before {
  content: "\e9e4";
}
.oh-exclamatory:before {
  content: "\e9e1";
}
.oh-questionmark:before {
  content: "\e9e2";
}
.oh-foldererror:before {
  content: "\e9e5";
}
.oh-gridcollapse:before {
  content: "\e9de";
}
.oh-gridexpandfilled:before {
  content: "\e9df";
}
.oh-gridexpand:before {
  content: "\e9e0";
}
.oh-folderopen:before {
  content: "\e9dd";
}
.oh-check:before {
  content: "\e9dc";
}
.oh-folderclosed:before {
  content: "\e9d6";
}
.oh-startarrow:before {
  content: "\e9da";
}
.oh-endarrow:before {
  content: "\e9db";
}
.oh-upload:before {
  content: "\e9d5";
}
.oh-download:before {
  content: "\e9d9";
}
.oh-refreshsmb:before {
  content: "\e9d7";
}
.oh-refreshsm:before {
  content: "\e9d8";
}
.oh-editsm:before {
  content: "\e9d3";
}
.oh-addsm:before {
  content: "\e9d4";
}
.oh-DoubleRightArrow:before {
  content: "\e9c9";
}
.oh-DoubleLeftArrow:before {
  content: "\e9ca";
}
.oh-caret-right:before {
  content: "\e958";
}
.oh-caret-left:before {
  content: "\e9c6";
}
.oh-LeftArrowSM:before {
  content: "\e9c7";
}
.oh-UpArrowSM:before {
  content: "\e9c8";
}
.oh-history:before {
  content: "\e950";
}
.oh-RSD:before {
  content: "\e950";
}
.oh-monitor:before {
  content: "\e951";
}
.oh-preview:before {
  content: "\e951";
}
.oh-otherpayment:before {
  content: "\e952";
}
.oh-warning-line:before {
  content: "\e953";
}
.oh-info-line:before {
  content: "\e954";
}
.oh-together:before {
  content: "\e955";
}
.oh-joinarrow:before {
  content: "\e955";
}
.oh-confirm-circle:before {
  content: "\e956";
}
.oh-help-circle:before {
  content: "\e956";
}
.oh-reciving:before {
  content: "\e957";
}
.oh-return:before {
  content: "\e957";
}
.oh-patientreg:before {
  content: "\e959";
}
.oh-REG:before {
  content: "\e959";
}
.oh-verifypatientwithrecords:before {
  content: "\e95a";
}
.oh-VPR:before {
  content: "\e95a";
}
.oh-patientcheckin:before {
  content: "\e95b";
}
.oh-CIN:before {
  content: "\e95b";
}
.oh-assignroom:before {
  content: "\e95c";
}
.oh-ASR:before {
  content: "\e95c";
}
.oh-unassignroom:before {
  content: "\e95d";
}
.oh-USR:before {
  content: "\e95d";
}
.oh-billingdetails:before {
  content: "\e95e";
}
.oh-EBD:before {
  content: "\e95e";
}
.oh-patientrelease:before {
  content: "\e95f";
}
.oh-RLS:before {
  content: "\e95f";
}
.oh-addressverified:before {
  content: "\e960";
}
.oh-av:before {
  content: "\e960";
}
.oh-antv:before {
  content: "\e961";
}
.oh-addressneedtoverify:before {
  content: "\e961";
}
.oh-anv:before {
  content: "\e962";
}
.oh-addressnotverified:before {
  content: "\e962";
}
.oh-Keyboard:before {
  content: "\e963";
}
.oh-keyboard:before {
  content: "\e963";
}
.oh-info:before {
  content: "\e964";
}
.oh-search:before {
  content: "\e965";
}
.oh-envelope:before {
  content: "\e966";
}
.oh-logout:before {
  content: "\e967";
}
.oh-circle:before {
  content: "\e968";
}
.oh-close:before {
  content: "\e969";
}
.oh-mapmarker:before {
  content: "\e96a";
}
.oh-circle-filled:before {
  content: "\e96b";
}
.oh-info-circle:before {
  content: "\e96c";
}
.oh-success-circle:before {
  content: "\e96d";
}
.oh-warning:before {
  content: "\e96e";
}
.oh-error-circle:before {
  content: "\e96f";
}
.oh-documents:before {
  content: "\e970";
}
.oh-stethoscope:before {
  content: "\e971";
}
.oh-notification2:before {
  content: "\e972";
}
.oh-notification:before {
  content: "\e972";
}
.oh-bell:before {
  content: "\e972";
}
.oh-notes:before {
  content: "\e973";
}
.oh-advice:before {
  content: "\e974";
}
.oh-bed:before {
  content: "\e975";
}
.oh-leftarrow:before {
  content: "\e976";
}
.oh-rightarrow:before {
  content: "\e977";
}
.oh-key:before {
  content: "\e978";
}
.oh-user:before {
  content: "\e979";
}
.oh-lock:before {
  content: "\e97a";
}
.oh-add:before {
  content: "\e97b";
}
.oh-change:before {
  content: "\e97c";
}
.oh-delete:before {
  content: "\e97d";
}
.oh-clear:before {
  content: "\e97e";
}
.oh-eraser:before {
  content: "\e97f";
}
.oh-dots:before {
  content: "\e980";
}
.oh-ProcessCard:before {
  content: "\e981";
}
.oh-note:before {
  content: "\e982";
}
.oh-CheckEligibility:before {
  content: "\e983";
}
.oh-DocAttach:before {
  content: "\e984";
}
.oh-Printer:before {
  content: "\e985";
}
.oh-SendMail:before {
  content: "\e986";
}
.oh-Fax:before {
  content: "\e987";
}
.oh-GroupNote:before {
  content: "\e988";
}
.oh-NoteasAlert:before {
  content: "\e989";
}
.oh-NoteasTask:before {
  content: "\e98a";
}
.oh-Link:before {
  content: "\e98b";
}
.oh-DLink:before {
  content: "\e98c";
}
.oh-plus:before {
  content: "\e98d";
}
.oh-minus:before {
  content: "\e98e";
}
.oh-Document:before {
  content: "\e98f";
}
.oh-DeleteList:before {
  content: "\e990";
}
.oh-CollapseArrow:before {
  content: "\e991";
}
.oh-RightArrowSM:before {
  content: "\e991";
}
.oh-ExpandArrow:before {
  content: "\e992";
}
.oh-DownArrowSM:before {
  content: "\e992";
}
.oh-AddPatientData:before {
  content: "\e993";
}
.oh-ConvertToPatient:before {
  content: "\e994";
}
.oh-AsignTo:before {
  content: "\e995";
}
.oh-ProcessRelease:before {
  content: "\e996";
}
.oh-ProcessHold:before {
  content: "\e997";
}
.oh-WebInfo:before {
  content: "\e998";
}
.oh-copy:before {
  content: "\e999";
}
.oh-open:before {
  content: "\e99a";
}
.oh-OrderRecord:before {
  content: "\e99b";
}
.oh-PaymentCash:before {
  content: "\e99c";
}
.oh-wallet:before {
  content: "\e99c";
}
.oh-Apply:before {
  content: "\e99d";
}
.oh-Ignore:before {
  content: "\e99e";
}
.oh-NotAllowed:before {
  content: "\e99e";
}
.oh-ProcStable:before {
  content: "\e99e";
}
.oh-Reverse:before {
  content: "\e99f";
}
.oh-LeftPointer:before {
  content: "\e9a0";
}
.oh-RightPointer:before {
  content: "\e9a1";
}
.oh-edit:before {
  content: "\e9a2";
}
.oh-SetPassword:before {
  content: "\e9a3";
}
.oh-Process:before {
  content: "\e9a4";
}
.oh-RelatedInfo:before {
  content: "\e9a5";
}
.oh-Flag:before {
  content: "\e9a6";
}
.oh-SerialNumber:before {
  content: "\e9a7";
}
.oh-CheckAvailability:before {
  content: "\e9a8";
}
.oh-NoDocument:before {
  content: "\e9a9";
}
.oh-Save:before {
  content: "\e9aa";
}
.oh-inactive:before {
  content: "\e9ab";
}
.oh-radialmenu:before {
  content: "\e9c2";
}
.oh-radialmenuhover:before {
  content: "\e9c3";
}
.oh-refresh:before {
  content: "\e9c4";
}
.oh-refreshhover:before {
  content: "\e9c5";
}
.oh-cheque:before {
  content: "\e9ac";
}
.oh-cash:before {
  content: "\e9ad";
}
.oh-verify:before {
  content: "\e9ae";
}
.oh-ach:before {
  content: "\e9af";
}
.oh-manual:before {
  content: "\e9b0";
}
.oh-system:before {
  content: "\e9b1";
}
.oh-printpreview:before {
  content: "\e9b2";
}
.oh-view:before {
  content: "\e9b3";
}
.oh-eye-open:before {
  content: "\e9b3";
}
.oh-creditcard:before {
  content: "\e9b4";
}
.oh-ok:before {
  content: "\e9b5";
}
.oh-calc:before {
  content: "\e9b6";
}
.oh-submit:before {
  content: "\e9b7";
}
.oh-submitlater:before {
  content: "\e9b8";
}
.oh-processlater:before {
  content: "\e9b9";
}

.ts-css .row-even {
    background-color: red;
}

.ts-css .row-odd {
    background-color: green;
}

/* .table-head-color {
    background-color: #f6f6f6 !important;
    border-bottom: 0px !important;

}
.cell-remove-bottom-border{
    border-bottom: 0px !important;
} */

/* .custom-accordion{
    border: 0px !important;
} */


/* styles for custom table grid start */
.ts-css .mTableCustom.MuiTableContainer-root {
    border: 1px solid #ddd;
    box-shadow: none !important;
  }
.ts-css .mTableCustom thead th:first-child {
    width: 38% !important;
  }
  .ts-css .mTableCustom thead th:not(:first-child) {
    width: 8% !important;
  }
  .ts-css .mTableCustom tr.level1 td:first-child {
    width: 37% !important;
  }
  .ts-css .mTableCustom tr.level1 td:not(:first-child) {
    width: 8% !important;
  }
  .ts-css .mTableCustom tr.level2 td:first-child {
    width: 37% !important;
    border-bottom: 1px solid #f5f5f5 !important;
  }
  .ts-css .mTableCustom tr.level2 td:not(:first-child) {
    width: 8% !important;
    border-bottom: 1px solid #f5f5f5 !important;
  }
  .ts-css .mTableCustom
    .level0
    .MuiAccordionSummary-root
    + .MuiCollapse-vertical
    .MuiAccordionDetails-root {
    padding: 0px 16px 0px 32px !important;
  }
  .ts-css .mTableCustom .level0.MuiTableCell-root {
    border-bottom: 1px solid #ebebeb !important;
  }
  .ts-css .mTableCustom .level0 .MuiAccordionSummary-content {
    margin: 0px !important;
  }
  .ts-css .mTableCustom .level1 .MuiTableCell-root {
    border-bottom: none !important;
  }
  
  .ts-css .mTableCustom .MuiPaper-root.MuiAccordion-root {
    border: none !important;
  }
/* Assuming you have a global stylesheet or CSS module */
/* .mTableCustom .MuiAccordionSummary-root:nth-child(odd) {
  background-color: #f7f7f7 !important;
}

.mTableCustom .MuiAccordionSummary-root:nth-child(even) {
  background-color: #ffffff !important;
}

.mTableCustom .MuiAccordionDetails-root .MuiTableRow-root:nth-child(odd) {
  background-color: #f7f7f7 !important;
}

.mTableCustom .MuiAccordionDetails-root .MuiTableRow-root:nth-child(even) {
  background-color: #ffffff !important;
} */

.ts-css .button-styles-filter{
        color: #333 !important;
        /* font-weight: 500; */
        background-color: #fff !important;
        border: 1px solid #20c2ec !important;
        padding: 2px 3px !important;
        border-radius: 4px !important;
        width: 100% !important;
        transition: 0.4s !important;
        font-size: 12px !important;
        height: 33px !important;
}

.ts-css .justify-center{
    justify-content: center !important;
}

.ts-css .custom-popover{
  top: calc(5px + 5px);
  left: calc(5px - 13px);
}
.ts-css .justify-right{
  justify-content: right !important;
}


.ts-css .input-date-label-space .MuiStack-root {
  overflow-x: hidden !important;
  padding-top: 4px !important;
}
.ts-css .custom-input .MuiInputBase-root {
  height: 43px !important;
  font-size: 12px !important;
}
.ts-css .custom-input .MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  padding-top: 4px !important;
}
.ts-css .custom-input {
  padding: 5px !important;
  font-size: 12px !important;
}
.pad-0 {
    padding: 0px !important;
}

.pad-10 {
    padding: 10px !important;
}
.pad-5 {
    padding: 5px !important;
}

/* .mar-0 {
    margin: 0px !important;
} */

.pad-left-0 {
    padding-left: 0px !important;
}

.pad-bottom-0 {
    padding-bottom: 0px !important;
}

.pad-right-0 {
    padding-right: 0px !important;
}

.pad-top-0 {
    padding-top: 0px !important;
}
.pad-top-2 {
    padding-top: 2px !important;
}

.margin-top-0 {
    margin-top: 0px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-2 {
    margin-top: 2px !important;
}

.pad-top-5 {
    padding-top: 5px !important;
}

.pad-top-6 {
    padding-top: 6px !important;
}

.pad-top-8 {
    padding-top: 68px !important;
}

.pad-top-4 {
    padding-top: 4px !important;
}

.pad-top-1 {
    padding-top: 1px !important;
}

.pad-top-7 {
    padding-top: 7px !important;
}

.pad-top-6 {
    padding-top: 6px !important;
}

.pad-top-10 {
    padding-top: 10px !important;
}

.pad-bottom-5 {
    padding-bottom: 5px !important;
}

.pad-left-15 {
    padding-left: 15px !important;
}

.pad-left-27 {
    padding-left: 43px !important;
}

.pad-bottom-0 {
    padding-bottom: 0px !important;
}

.margin-left-0 {
    margin-left: 0px !important;
}

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-left-15 {
    margin-left: 22% !important;
}

.margin-left-28 {
    margin-left: 28% !important;
}

.margin-left-46 {
    margin-left: 46px !important;
}

.margin-right-0 {
    margin-right: 0px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}
.margin-right-4 {
    margin-right: 4px !important;
}

.margin-bottom-0 {
    margin-bottom: 0px !important;
}

.margin-bottom-1 {
    margin-bottom: 1px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-2 {
    margin-bottom: 2px !important;
}

.pad-right-5 {
    padding-right: 5px !important;
}

.pad-right-13 {
    padding-right: 13px !important;
}

.pad-left-5 {
    padding-left: 5px !important;
}

.margin-bottom-4 {
    margin-bottom: 4px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-8 {
    margin-top: 8px !important;
}

/* .margin-top-10 {
    margin-top: 10px !important;
} */

/* .margin-top-3 {
    margin-top: 6px !important;
} */

.margin-bottom-4 .MuiFormLabel-root {
    margin-bottom: 4px !important;
}

.confirmationQuestion {
    font-weight: 500 !important;
    background-color: #e4e6eab8 !important;
    margin-bottom: 0px !important;
}

.table-bottom-spacing {
    margin-bottom: 22px !important;
}

.custom-border-left {
    border-top: 1px solid #e4e4e4 !important;
    border-bottom: 1px solid #e4e4e4 !important;
    border-left: 1px solid #e4e4e4 !important;
}

.border-right {
    /* border: 1px solid #e0e0e0 !important; */
    border: 1px solid #e4e4e4 !important;
}

.custom-input .MuiInputBase-root {
    height: 33px !important;
    font-size: 13px !important;
}

.custom-input-disabled .MuiInputBase-root {
    height: 33px !important;
    font-size: 13px !important;
    
}

/* .custom-input-label .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
    font-size: 12px !important;
    padding: 5px 0px 5px 0px !important;
} */

.custom-input .MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    padding-left: 10px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;
}

/* .custom-input-disabled .MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    padding-left: 10px !important;
    padding-bottom: 0px !important;
    padding-top: 4px !important;
} */

.custom-input {
    padding: 5px !important;
    font-size: 13px !important;
}

.custom-input-disabled {
    padding: 5px !important;
    font-size: 12px !important;
}

.custom-claim-input {
    padding: 2px 5px 5px 5px !important;
    font-size: 12px !important;
}

.custom-claim-input .MuiInputBase-root {
    height: 33px !important;
    font-size: 12px !important;
}

.custom-label {
    font-size: 12px !important;
}

.custom-label .MuiButtonBase-root-MuiChip-root {
    font-size: 12px !important;
}

.custom-label .MuiFormLabel-root {
    margin-bottom: 0px !important;
}

.custom-radio .MuiFormControlLabel-label {
    font-size: 13px !important;
}

.cms-css .muitextbox {
    padding-top: 22px !important;
}

.custom-radio-group {
    padding-left: 15px !important;
    padding-top: 3px !important;
    display: flex !important;

}
.custom-patient-radio-group {
    /* padding-left: 15px !important; */
    /* padding-top: 3px !important; */
    display: flex !important;

}

.custom-radio-group .MuiButtonBase-root-MuiRadio-root {
    padding: 3px !important;
    
}
.custom-spacing-nucc {
    padding-top: 21px !important;
}

.custom-spacing-relation {
    padding-top: 7px !important;
}
.custom-spacing-accidence {
    padding-top: 2px !important;
}
.custom-spacing-auto-acc {
    padding-top: 2px !important;
}

.custom-patient-condt {
    /* display: flex !important; */
    align-items: center !important;
    padding: 7px 5px 5px !important;
}

.pad-left-10 {
    padding-left: 10px !important;
}

.custom-claim-code {
    padding-top: 2px !important;
}

.custom-spacing-top {

    padding-top: 40px !important;
}

.custom-spacing-state {
    padding: 20px 5px 5px 5px !important;
}

.custom-claim-id {
    padding-top: 10px !important;
}

.cms-css .custom-auth {
    padding-bottom: 12px !important;
}


.custom-label-input {
    padding: 0px 3px 0px !important
}

.custom-label-input .MuiInputBase-root {
    height: 22px !important;
    font-size: 12px !important;
}

.custom-label-phn {
    padding: 0px 3px 0px !important
}

.custom-label-phn .MuiInputBase-root {
    height: 22px !important;
    font-size: 12px !important;
    width: 100px !important;
}

.custom-aline-tax {
    padding-bottom: 39px !important;
}

.custom-spacing-tax {
    padding-top: 20px !important;
}

.custom-placeholder .MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px !important;
    padding-left: 5px !important;
}

.custom-bill-align {
    padding-top: 5px !important;
}

.text-align-left {
    text-align: left !important;
}

.text-align-right {
    text-align: right !important;
}

.custom-datepicker .MuiInputBase-root {
    height: 33px !important;
    max-width: 100% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
    min-width: 100% !important;
}

.custom-datepicker-from .MuiInputBase-root {
    height: 33px !important;
    /* max-width: 85% !important; */
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
}

.custom-datepicker-from-filter .MuiInputBase-root {
    height: 33px !important;
    max-width: 100% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
}

.custom-datepicker-claim .MuiInputBase-root {
    height: 33px !important;
    /* max-width: 150% !important; */
    max-width: 1100px !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
}

.custom-datepicker-to .MuiInputBase-root {
    height: 33px !important;
    max-width: 93% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
}

.custom-datepicker-to-grid .MuiInputBase-root {
    height: 33px !important;
    max-width: 69% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
}

.custom-datepicker-from-grid .MuiInputBase-root {
    height: 33px !important;
    max-width: 69% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
}

.custom-datepicker .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important;
}

.custom-datepicker-from .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important;
}

.custom-datepicker-from-filter .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important;
}


.custom-datepicker-claim .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important;
}

.custom-datepicker-to .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important;
}

.custom-datepicker-to-grid .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important;
}

.custom-datepicker-from-grid .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 0px !important
}

.custom-datepicker .MuiStack-root {
    padding: 5px !important;
}

/* .input-date-spacing {
    padding: 5px !important;
} */

.input-date-spacing .MuiStack-root {
    padding-top: 0px !important;
    overflow-x: hidden !important;
}

.input-date-label-space .MuiStack-root {
    overflow-x: hidden !important;
    padding-top: 4px !important;
}

.input-date-label-claim .MuiStack-root {
    overflow-x: hidden !important;
    padding: 0px 5px 5px 5px !important;

}

.margin-top-4 {
    margin-top: 4px !important;
}

.margin-top-3 {
    margin-top: 3px !important;
}

.custom-aline-amnt {
    padding-bottom: 22px !important;
}

/* .custom-bill-align .css-mhc70k-MuiGrid-root>.MuiGrid-item {
    padding-left: 0px !important;
} */


.custom-bill-align>.MuiGrid-item {
    padding-left: 10PX !important;
}

.custom-bill-align>.MuiGrid-item:first-child,
.custom-bill-align>.MuiGrid-item:nth-child(7) {
    padding-left: 16px !important;
}

.adjust-flex-custom-first {
    max-width: 40.9% !important;
    flex-basis: 40.9% !important;
}

.adjust-flex-custom-last {
    flex-basis: 9.1% !important;
    max-width: 9.1% !important;
}

.cms-css .MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px !important;
}

.custom-spacing-next {
    padding-top: 1px !important;
}

.custom-spacing-auto {
    padding-top: 0px !important;
}

.custom-grid-button {
    font-size: 12px !important;
    /* padding: 2px 7px !important; */
}

.custom-Cms-button {
    font-size: 12px !important;
    padding: 2px 7px !important;
}


.custom-datagrid {
    height: auto !important;
    width: 100% !important;
    outline: none !important;
}

.grid-input {
    border: 1px solid #e0e0e0 !important;
    width: 100px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.custom-grid-header {
    border: 1px solid #e0e0e0 !important;
    width: 300px !important;
    text-align: center !important;
}

.grid-inside-header {
    border: 1px solid #e0e0e0 !important;
    width: 150px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.grid-inside-header-cpt {
    border: 1px solid #e0e0e0 !important;
    width: 133px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    gap: 3px !important
}

.grid-inside-header-modifier {
    border: 1px solid #e0e0e0 !important;
    /* width: 150px !important; */
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
}

.custom-grid-width {
    width: 300px !important;
}

.custom-grid-body {
    width: max-content !important;

}

.custom-grid-container {
    overflow-x: scroll !important;
    width: auto !important;
    border: 1px solid #bdbdbd !important;
    /* border-radius: 4px !important; */
    text-align: center !important;
    color: #000000 !important;
}

.custom-grid-content {
    background-color: #ecedf0 !important;
    font-weight: bold !important;
    width: max-content !important;
}

.custom-label-length {
    padding-bottom: 23px !important;
}

.custom-label-infophn {
    padding-bottom: 2px !important;
}
/* 
.content-cms-form {
    padding-left: 10px !important;
} */

.custom-width-last .MuiStack-root {
    padding: 5px !important;
}

.custom-width-last .MuiInputBase-root {
    height: 33px !important;
    max-width: 100% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    text-align: center !important;
    font-size: 12px !important;
    /* min-width: 230px !important; */
   
}

.custom-width-conf-qn .MuiStack-root {
    padding: 5px !important;
}

.custom-width-conf-qn .MuiInputBase-root {
    height: 33px !important;
    max-width: 100% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    text-align: center !important;
    font-size: 12px !important;
    min-width: 242px !important;

}

.custom-datepicker-other .MuiInputBase-root {
    height: 33px !important;
    max-width: 100% !important;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
    font-size: 12px !important;
    min-width: 218px !important;
}

.custom-datepicker-other .MuiFormLabel-root {
    text-align: center !important;
    font-size: 12px !important;
    top: 3px !important
}

.custom-datepicker-other .MuiStack-root {
    padding: 5px !important;
}

.div-alignment {
    padding: 0px !important;
}

@media (min-width: 600px) {
    .spacing-container .MuiContainer-root {
        padding-left: 7px !important;
        padding-right: 0px !important;
    }
}

@media (min-width: 600px) {
    .claim-container .MuiContainer-root {
        padding-left: 15px !important;
        padding-right: 0px !important;
    }
}

.claim-header-field.MuiBox-root{
    padding: 20px 0px 0px 0px !important;
}

.pagination-alignment .MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
    color: white !important;
    border: 1px solid rgba(25, 118, 210, 0.5) !important;
    background-color: #20c2ec !important;
}

.pagination-container {
    display: flex !important;
    justify-content: flex-end !important;
    /* padding-left: 25% !important; */
    padding-left: 45% !important;
    padding-top: 5px !important;
}

/* Masterpage claim cms */
.masterpage-claim-cms {
    padding: 25px 25px 25px 25px !important;
}

/* .custom-static-head> .MuiAccordionSummary-content .Mui-expanded{
        margin: 0px !important;
        background-color:aqua !important;
    } */

.adjust-flex-or {
    max-width: 3.3333% !important;
    flex-basis: 3.3333% !important;
}

.adjust-flex-input {
    max-width: 25.5333% !important;
    flex-basis: 25.53333% !important;
}

.custom-input-label {
    padding: 5px 5px 5px 0px !important;
}

/* .custom-input-label .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    top: 9px !important;
} */

.custom-input-label .MuiInputBase-root {
    height: 33px !important;
    font-size: 12px !important;
}

.custom-input-label .MuiFormLabel-root {
    top: 7px !important;
    text-align: center !important;
    font-size: 13px !important;
}

.custom-grid-button:hover {
    background-color: #20c2ec;
    color: #fff;
}

.custom-aline-claim {
    display: flex !important;
    align-items: center !important;
}

.custom-claim-label {
    min-width: 130px !important;
    font-size: 12px !important;
}

.custom-required .MuiFormLabel-asterisk {
    color: red !important;
    font-size: 20px !important;
}

.width-100 {
    width: 100% !important;
}

.new-line {
    display: block !important;
    font-weight: normal !important;
    font-size: 10px !important;
}

.font-bold {
    font-weight: 600 !important;
}

.pad-bottom-9 {
    padding-bottom: 9px !important;
}
.pad-bottom-6 {
    padding-bottom: 6px !important;
}

.custom-red {
    color: rgb(255, 0, 0);
}

/* .custom-claim-btn{
    max-width: 39% !important;
    flex-basis: 39% !important;
}
.custom-claim-btnsearch{
    max-width:17% !important;
}
.custom-claim-btnclear{
    max-width:14% !important;
    padding-left: 10px !important;
} */

.custom-claim-btn {
    max-width: 100% !important;
}

.custom-claim-btnsearch {
    max-width: 100% !important;
}

.custom-claim-btnclear {
    max-width: 100% !important;
    padding-left: 10px !important;
}

@media (min-width: 600px) {
    .custom-claim-btn {
        max-width: 39% !important;
        flex-basis: 39% !important;
    }

    .custom-claim-btnsearch {
        max-width: 17% !important;
    }

    .custom-claim-btnclear {
        max-width: 14% !important;
    }
}

.custom-grid-button {
    width: 100% !important;
    height: 100% !important;
}

.custom-claim-pagination {
    display: flex !important;
    /* justify-content: space-between !important; */
    gap: 10px !important;
    align-items: center !important;
}

.custom-claim-btngroup {
    display: flex !important;
    justify-content: flex-start !important;
    gap: 10px !important;
}

.custom-grid-divider {
    display: flex !important;
    justify-content: center !important;
}

.divider-width {
    width: 100% !important;
}

.divider-width-contact {
    width: 100% !important;
}

.custom-label-claim {
    color: black !important;
    font-size: 12px !important;
}

.claim-status-edit-view {
    padding: 7px 8px !important;
    color: #333 !important;
    background: yellow !important;
    border-radius: 4px !important;
    display: inline-block !important;
    width: 350% !important;
    text-align: center !important;
    font-size: 12px !important;
    height: 33px !important;
}


.claim-status-view {
    width: auto !important;
}

.custom-input-disabled {
    cursor: not-allowed !important;

}

.cms-css .Mui-disabled {
    cursor: not-allowed !important;
    -webkit-text-fill-color: #000000 !important;
}

.cms-css .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
    color: #0a0a0a !important;
}

.width-80 {
    width: 80% !important;
}

.width-70 {
    width: 80% !important;
}

.width-60 {
    width: 60% !important;
}

.width-65 {
    width: 65% !important;
}

.width-150 {
    width: 150% !important;
}

.pad-right-15 {
    padding-right: 15px !important;
}

.custom-input-phn {
    padding-left: 50px !important
}

.custom-side-header-contact {
    max-width: 13.9% !important;
    flex-basis: 13.9% !important;
}

.custom-side-header-add {
    max-width: 6.9% !important;
    flex-basis: 6.9% !important;
}

.custom-phn-extension {
    max-width: 10.9% !important;
    flex-basis: 10.9% !important;
}

.custom-email-spacing {
    max-width: 24.9% !important;
    flex-basis: 24.9% !important;
}

.custom-delete-spacing {
    max-width: 3.9% !important;
    flex-basis: 3.9% !important;
}

.delete-color {
    color: #da0a0a !important;
}

@media (min-width: 600px) {
    .font-ddl .MuiButtonBase-root-MuiMenuItem-root {
        font-size: 12px !important;
    }
}

.custom-input-label .MuiAutocomplete-inputRoot {
    width: 100%;
}

.custom-input-label .MuiOutlinedInput-root {
    width: 100%;
}

.custom-input-label .MuiAutocomplete-input {
    width: 100%;
    box-sizing: border-box;
}

.custom-input-label .MuiOutlinedInput-input {
    padding: 8.5px 14px;
}

.custom-input-label .MuiTextField-root {
    min-width: 100px !important;
}

.error-header-width {
    width: 100% !important;
}

.custom-icon-claim {
    font-size: 18px !important;
}

.custom-label-textarea .MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 12px !important;
}

.button-container {
    display: flex !important;
    gap: 5px !important;
    padding-top: 5px !important;

}

.custom-payer-button {
    font-size: 12px !important;
    /* padding: 2px 7px !important; */
    min-width: 0 !important;
    padding: 6px;
    width: 100% !important;
    height: 33px !important;
    /* height: 100% !important; */
}

.custom-payer-button:hover {
    background-color: #20c2ec;
    color: #fff;
}

.custom-placeholder-divider .MuiInputAdornment-root {
    margin-right: 0px !important;
}

.txt-placeholder-container {
    display: flex !important;
    align-items: center !important;
}

.fontbold {
    font-weight: bold !important;
}

.divider-container {
    height: 24px !important;
    margin: 0 8px !important;
    font-weight: bold !important;
}

.adjust-flex-payer-priority {
    max-width: 9.9% !important;
    flex-basis: 9.9% !important;
}

.adjust-flex-payer-id {
    max-width: 9.9% !important;
    flex-basis: 9.9% !important;
}

.adjust-flex-claim-btn {
    max-width: 14.6% !important;
    flex-basis: 14.6% !important;
}
.adjust-flex-claim-btn-clear{
    max-width: 6.1% !important;
    flex-basis: 6.1% !important;
}
.adjust-flex-claim-btn-cancel{
    max-width: 6.1% !important;
    flex-basis: 6.1% !important;
}

.adjust-flex-claim-action {
    max-width: 23.0% !important;
    flex-basis: 23.0% !important;
}

.button-popstyles {
    max-height: 30px !important;
}

.custom-name-input {
    max-width: 38.5% !important;
    flex-basis: 38.5% !important;
}

.custom-middlename-input {
    max-width: 22.9% !important;
    flex-basis: 22.9% !important;
}


/* Mobile view */
@media (max-width: 600px) {
    .masterpage-claim-cms {
        padding: 0 !important;
    }

    .div-alignment .MuiContainer-root {
        padding: 0 !important;
    }
}

.custom-input-required {
    /* border: 1px solid red !important; */
    /* This will show a red border */
}

.custom-input-required .MuiOutlinedInput-notchedOutline {
    border-color: red !important;
    /* Ensures the outline is red */
}

.cms-css .MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    font-size: 17px !important;
}

.cms-css .MuiChip-label {
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.cms-css .MuiTypography-root {
    font-size: 12px !important;
}

.text-align-end {
    text-align: end !important;
}

.float-inline-end {
    float: inline-end !important;
}

.adjust-flex-custom-pagination {
    max-width: 54.333333% !important;
    flex-basis: 54.333333% !important;
}

.accordion-spacing .MuiAccordionDetails-root {
    padding: 8px 0px 8px 0px !important;
}

.cms-css .MuiPagination-ul {
    display: inline-flex !important;
}

.top-move {
    bottom: 0 !important;
}

.adjust-flex-custom-pagination {
    float: right !important;
}

.button-alignment {
    display: flex !important;
    /* padding-bottom: 25px !important; */

}

.align-record {
    margin-top: -16px !important;
}

.custom-dialog-btn {
    width: auto !important;
    float: right !important;
}

.adjust-flex-payer-name {
    flex-basis: 20.2% !important;
    max-width: 20.2% !important;
}
.adjust-flex-cpid {
    flex-basis: 15.2% !important;
    max-width: 15.2% !important;
}

.custom-chip-style .MuiChip-deleteIcon {
    font-size: 18px !important;
    margin-left: 0px !important;
}

.cms-css .MuiButtonBase-root.MuiChip-root.MuiChip-outlined.MuiChip-sizeMedium.MuiChip-colorDefault.MuiChip-deletable.MuiChip-deletableColorDefault.MuiChip-outlinedDefault.custom-chip-style.custom-label {
    height: 23px;
}

.custom-select-input .MuiFormLabel-root {
    top: 3px !important;
    text-align: center !important;
    font-size: 13px !important;
}

.custom-select-input .MuiFormLabel-root::after {
    top: 7px !important;
}

.custom-bg-danger .MuiInputBase-root {
    background-color: #f7e0e2 !important;
}

.cms-css .MuiStack-root>.MuiTextField-root {
    min-width: auto !important;
}


.cms-css .MuiContainer-root {
    padding-left: 28px !important;
    padding-right: 10px !important;
}
.customLabel-DatePicker .MuiFormLabel-root-MuiInputLabel-root {
    font-size: 12px  !important;
    text-align: center  !important;
    position: relative  !important; /* Adjust based on your layout */
    top: 7px  !important;
    left: 5px  !important;
}
.cms-css .MuiFormControlLabel-root{
    margin-right: 10px !important;
}

.cms-css .MuiButtonBase-root-MuiRadio-root
{
    padding:7px !important
}
.cms-css .textarea-height {
    height: 55px !important;
}
.cms-css .MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root{
    height:33px !important;
}
.font-size-13{
    font-size: 13px !important;
}
:root {
  --btn-clr: #6466e8;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* Header Component start */

.lbl837Claim {
  padding-left: 10px !important;
  font-size: 10px !important;
}

.lbl837ClaimColor {
  padding-left: 10px !important;
  font-size: 10px !important;
  color: red !important;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon.css-1ar8evj-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding-right: 46px !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-uduulw-MuiPaper-root {
  border: 1px solid gray !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-uduulw-MuiPaper-root.red {
  border: 1px solid red !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.css-9ttigj {
  border: 1px solid gray !important;
}

.MuiAutocomplete-input {
  /* font-size: 12px !important; */
}

.MuiAutocomplete-listbox {
  height: auto !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  margin: 12px 6px !important;
}

/* .MuiAutocomplete-popper.MuiAutocomplete-popperDisablePortal.css-1rndd9j-MuiPopper-root-MuiAutocomplete-popper.Mui-Popper-root {
  z-index: 99 !important;
} */

/* Header Component end */

/* Loader component start */

.loader-overlay {
  font-size: 38px;
  font-weight: 500;
  width: 10vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0);
  color: rgb(51, 51, 51);
  padding: 10px;
}

.loader-content {
  /* background-color: transparent !important; */
  opacity: 0.5;
}

/* Loader component end */

/* Button component start*/
.button-popstyles {
  color: #333;
  /* font-weight: 500; */
  background-color: #fff;
  border: 2px solid #20c2ec;
  padding: 4px 8px;
  border-radius: 4px;
  width: 60px !important;
  transition: 0.4s;
}

.button-styles {
  color: #333;
  /* font-weight: 500; */
  background-color: #fff;
  border: 1px solid #20c2ec;
  padding: 7px 8px;
  border-radius: 4px;
  width: 100%;
  transition: 0.4s;
}

.button-styles.disabled,
.button-styles.disabled:hover {
  color: rgba(117, 117, 117) !important;
  border: 1px solid rgba(0, 0, 0, 0.2) !important;
  background-color: #fff !important;
  pointer-events: none;
  cursor: default;
}

.button-styles:hover,
.button-popstyles:hover {
  color: #fff !important;
  background-color: #20c2ec !important;
}
.button-stylesDialog {
  color: var(--btn-clr);
  float: right;
}

.filled-button-styles {
  background-color: var(--btn-clr);
  /* font-weight: 500; */
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
}

.buttonHeader-styles {
  color: var(--btn-clr);
  /* font-weight: 500; */
  border: 2px solid var(--btn-clr);
  padding: 4px 8px;
  border-radius: 4px;
}
.css-1k23hlb-MuiButtonBase-root-MuiButton-root {
  color: #6466e8 !important;
  /* font-weight: 500; */
  border: 2px solid #6466e8 !important;
}
.css-k58djc {
  color: #6466e8 !important;
  /* font-weight: 500; */
  border: 2px solid #6466e8 !important;
}

.filled-buttonHeader-styles {
  background-color: var(--btn-clr);
  /* font-weight: 500; */
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
}

.css-uduulw-MuiPaper-rootOver {
  border: 1px solid red !important;
}

/* Button component end*/

/* login page start*/

.LoginBackgroundImage {
  background-repeat: no-repeat;
  transform: rotate(180deg);
}

.overflow-text {
  font-size: 38px;
  font-weight: 500;
  width: 36vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0);
  color: rgb(51, 51, 51);
  padding: 10px;
}

@media only screen and (max-width: 768px) {
  .overflow-text {
    font-size: 28px;
  }
}

.forgot-password-styles {
  color: var(--btn-clr);
}

.login-button {
  background-color: #20c2ec;
  font-weight: 500;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
}

.login-button:hover {
  background-color: #20b8de;
}

/* Login page end */

/* Patient list start */
.search-button {
  color: var(--btn-clr) !important;
  border: 1px solid var(--btn-clr) !important;
}

.search-icon {
  color: var(--btn-clr) !important;
  z-index: 1 !important;
}

.search-icon-blur {
  z-index: 2 !important;
  color: var(--btn-clr) !important;
}

.filterButton {
  padding: 8px !important;
  color: var(--btn-clr) !important;
  border: 1px solid var(--btn-clr) !important;
  height: 100%;
}

.filterButtonBlur {
  padding: 11px !important;
  color: var(--btn-clr) !important;
  border: 1px solid var(--btn-clr) !important;
  z-index: -1 !important;
  height: 100% !important;
}

.MuiList-root.MuiList-padding.MuiMenu-list.css-6hp17o-MuiList-root-MuiMenu-list {
  box-shadow: none !important;
}

/* Patient list end */

/* CaptureDrivingLicense page start */
.uploadButton {
  background-color: #20c2ec !important;
  color: white !important;
}

/* CaptureDrivingLicense page end */

/* Patient Details Start */
@media screen and (min-width: 768px) {
  .vertical-line::before {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #737272;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Patient Details End */

/* Send SMS page start */
.react-tel-input {
  height: 54px !important;
  border: 1.5px solid #c4c4c4 !important;
  border-radius: 4px !important;
  width: 100% !important;
  margin: 12px 12px !important;
  /* margin: 8px 0px 0px 9px; */
}

.react-tel-input > input {
  height: 52px !important;
  width: 100% !important;
  padding: 0px 10px !important;
}

.react-tel-input .country-list {
  z-index: 5 !important;
}
.datagridWraptxt {
  white-space: normal;
}

.special-label {
  display: none;
}
.gridImageDetails {
  height: calc(100vh - 300px) !important;
  object-fit: contain;
}

.css-6hp17o-MuiList-root-MuiMenu-list {
  height: fit-content !important;
  max-height: 160px !important;
}

/* Send SMS page end */

/* .MuiFormControl-root
  .MuiTextField-root
  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100% !important;
} */

/* captureDrivingKicense page start */

.imgwidthCard {
  width: 100% !important;
}

.image-container {
  /* width: 450px;
height: 250px; */
  overflow: hidden;
}

/* body {
  overflow: auto !important;
  padding-right: 0px !important;
} */

.imageDetailsHeight {
  height: 205px !important;
}

.flterZIndex {
  z-index: 1 !important;
}

/* .image-container img {
  /* width: 100%; */
/* height: 100%; */
/* object-fit: cover; */
/* }  */

@media only screen and (min-width: 768px) {
  .image-container img {
    height: 100%;
  }

  .imageFullWidth {
    width: 50%;
    float: left;
  }
}

@media only screen and (max-width: 499px) {
  .btnHeight {
    min-height: 60px !important;
  }
  .btnlineHeight {
    line-height: 13px !important;
  }

  .btnlineleft {
    padding-left: 50px !important;
  }

  .btnHeaderHeight {
    min-height: 50px !important;
  }
}

@media only screen and (max-width: 900px) {
  .btnHeight {
    min-height: 60px !important;
  }
  .pad-rt-20 {
    padding-right: 30px;
  }
  .btnlineHeight {
    line-height: 13px !important;
  }
  .btnlineleft {
    padding-left: 50px !important;
  }
  .btnHeaderHeight {
    min-height: 50px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .btnlineHeight {
    line-height: 16px !important;
  }
  .btnHeaderHeight {
    min-height: 50px !important;
  }
  .btnlineleft {
    padding-left: 25px !important;
  }
}

.patientGrdDynHgt {
  height: calc(100vh - 210px) !important;
}
.docVerfyGrdDynHgt {
  height: calc(100vh - 220px) !important;
}

.singleDocHgt {
  height: calc(100vh - 160px) !important;
  overflow: auto;
}
.reportsGriddynHgt {
  height: calc(100vh - 205px) !important;
}
.reportScreendynHgt {
  height: calc(100vh - 370px) !important;
}
.MuiDrawer-paper {
  min-width: 15% !important;
}
.sideBarCss .MuiDrawer-paper {
  width: 243px !important;
}
.acc .Mui-expanded {
  /* Your styles here */
  min-height: 0px !important;
  margin: 12px 0 !important;
}
.legend {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 17rem;
}
.zoomContainer {
  border: 1px solid #ddd;
  position: relative;
}

.zoomiconOver {
  position: absolute;
  width: 100%;
  bottom: 0px;
  background: rgba(0, 0, 0, 0.2);
}

.imageIconsOver {
  border: 1px solid #ccc;
  border-top: 0px;
}

.imageIconOver1 {
  margin-top: 0px !important;
  padding-top: 10px !important ;
}

.opuperrorMesg {
  color: red !important;
}

.skip_block {
  display: table;
  margin: auto;
  font-size: 17px;
  background: #fff5a1;
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #ffe92d;
  width: 95.5%;
  text-align: center;
}

.muidatagridbgcolor {
  background: #eeeeee !important;
}

.lblBreadCums {
  background: red !important;
}

.lblBreadCumsleave {
  background: none !important;
}

.breadcrumb li a:hover {
  cursor: pointer;
}

.MuiBreadcrumbs-li :hover {
  color: blue;
}

.css-1jtks4-MuiSvgIcon-root {
  color: blue !important;
}
.css-1g8nmq-MuiSvgIcon-root {
  color: blue !important;
}
.css-1yqeqmg {
  color: blue !important;
}
.css-icewqe {
  color: blue !important;
}
.imgDivWidth {
  min-width: 300px !important;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: green !important;
}
.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: red !important;
}
.css-4ff9q7.Mui-completed {
  color: green !important;
}
.css-4ff9q7.Mui-active {
  color: red !important;
}

.headerInputQR {
  appearance: none;
  outline: none;
  border: none;
  background: #eee;
  width: 100%;
  max-width: 320px;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
}
.menuPopupData {
  z-index: 1000;
  height: 400px;
  position: absolute;
  max-height: 300px !important;
  min-height: 100px !important;
  overflow: auto;
  /* opacity: 1; */
  /* border: 5px solid red; */
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}
.wid50p {
  margin-top: 10px;
}
.mar-bot-15 {
  margin-bottom: 15px;
}
.mar-left-10 {
  margin-left: 10px;
}
/* captureDrivingKicense end start */

/* pie-chart styles start  */

.customCardView {
  width: 48%;
  padding: 15px;
  margin: 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
.right {
  float: right;
}
.w-100 {
  width: 100vw;
  z-index: 999 !important;
}
.menuPopupData {
  z-index: 1000;
  height: 400px;
  position: absolute;
  max-height: 300px !important;
  overflow: auto;
  /* opacity: 1; */
}

.custom-text {
  font-size: 0.8rem; /* Decrease font size */
  line-height: 1.2; /* Adjust line height */
  max-height: 3.6em; /* Ensures three lines are shown based on line-height */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
  white-space: normal; /* Ensure text wraps correctly */
}

.myCustomClass > .MuiDataGrid-root {
  border: 2px solid #dedddd !important;
}

body.modal-open {
  overflow: hidden;
}

.css-1mhcdve-MuiPieArc-root {
  stroke: none !important;
  stroke-width: 1;
  stroke-linejoin: round;
}

.css-1b95g7z {
  stroke: none !important;
  stroke-width: 1;
  stroke-linejoin: round;
}

.MuiDataGrid-columnHeaders {
  font-weight: bold !important;
  font-size: 0.9rem !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  font-size: 0.9rem !important;
}
.btnOver:hover {
  color: black;
  background-color: rgb(163, 227, 250);
}

.fixWid200 {
  width: 168px;
  display: inline-block;
  text-align: right;
}

.minWid200 {
  min-width: 170px;
  display: inline-block;
  text-align: left;
  font-weight: 600;
}

.statusLabel {
  padding: 6px 8px;
  color: #333;
  background: yellow;
  margin-left: 20px;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  text-align: center;
}

input:read-only + .MuiOutlinedInput-notchedOutline,
div.read-only > .MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0.1) !important;
}

.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root:hover,
.MuiButtonBase-root.MuiPaginationItem-root:hover {
  background-color: rgba(32, 194, 236, 0.25) !important;
}

.css-rppfq7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: rgba(32, 194, 236, 1) !important;
  color: #fff !important;
}
.customReportsdg {
  width: 100%;
  height: 30rem;
}

.customReportsdg .MuiDataGrid-columnHeaders {
  background-color: #f5f5f5 !important;
  /* background-color: #e1e1e1 !important; */
}

.MuiButtonBase-root.filterButton,
.MuiButtonBase-root.MuiButton-root {
  border: 1px solid #20c2ec !important;
  color: #333;
  background-color: #fff;
}
/* ICON Styles */
.windowClose,
.secondaryIcon {
  color: #747474 !important;
  cursor: pointer;
}
.windowClose:hover,
.secondaryIcon:hover {
  color: #20c2ec !important;
}
.MuiButtonBase-root.MuiButton-root {
  width: auto;
}

.MuiButtonBase-root.filterButton svg,
.MuiButtonBase-root.search-icon svg {
  color: #747474 !important;
}
.MuiButtonBase-root.filterButton:hover {
  background-color: #20c2ec !important;
}
.MuiButtonBase-root.filterButton:hover svg {
  color: #fff !important;
}

.MuiButtonBase-root.MuiCheckbox-root.Mui-checked {
  color: #20c2ec !important;
}

.checkBox .MuiFormControlLabel-root {
  margin-right: 0px !important;
}

.checkBox .MuiButtonBase-root.MuiCheckbox-root {
  padding: 5px 4px !important;
}

.py-0 {
  padding: 0px !important;
}

.displayGrid .MuiStack-root {
  display: grid !important;
  margin-bottom: 8px;
}
.w-15p7 {
  width: 15.65rem !important;
}

.singleDocument-leftside:hover {
  border: 2px dashed #20c2ec;
}

.zoom_ctrl {
  position: absolute;
  top: 5px;
  left: 10px;
  /* background: #eaeaea;
  border-radius: 4px;
  border: 1px solid #ccc; */
}

.zoom_ctrl.posi_initial {
  width: 200px;
  text-align: center;
  display: flex;
  margin: 6px auto;
  padding: 2px;
  position: initial !important;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.zoom_ctrl img {
  width: 20px;
  height: 20px;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
}

.zoom_ctrl img:hover {
  background: #fff;
}

.qr_pop .MuiDialog-container .MuiDialog-paper {
  min-width: 820px !important;
  min-height: 460px !important;
}
.MuiDialog-container .MuiDialog-paper {
  min-width: 500px !important;
}
.MuiCollapse-wrapperInner
  .MuiListItem-root
  .MuiButtonBase-root.MuiListItemButton-root {
  padding-left: 45px !important;
}
.MuiListItem-root .MuiButtonBase-root.MuiListItemButton-root {
  background: #fafafa;
  margin: 1px 0px;
  border-bottom: 1px solid #ddd;
  transition: all 0.2s;
}

.MuiListItem-root .MuiButtonBase-root.MuiListItemButton-root:hover {
  background: #cff5ff !important;
  /* color: #fff !important; */
}

/* .MuiListItem-root .MuiButtonBase-root.MuiListItemButton-root:hover svg {
  color: #fff !important;
} */

.fltr-hgt span:first-child {
  height: 100% !important;
}

.tableCelBdrClr .MuiTableCell-root {
  border-bottom: 1px solid #f0f0f0 !important;
}

/* SessionOutModal styles */
.session-warning-modal .MuiBackdrop-root.MuiModal-backdrop {
  backdrop-filter: blur(3px) !important;
}

/* ag grid styles start here */
.insVHgt .ag-root-wrapper-body.ag-layout-auto-height,
.docRecHgt .ag-root-wrapper-body.ag-layout-auto-height {
  height: calc(100vh - 345px) !important;
}
.docVHgt .ag-root-wrapper-body.ag-layout-auto-height {
  height: calc(100vh - 310px) !important;
}
.tsHgt .ag-root-wrapper-body.ag-layout-auto-height {
  height: calc(100vh - 270px) !important;
}
.repScrHgt .ag-root-wrapper-body.ag-layout-auto-height {
  height: calc(100vh - 410px) !important;
}
.locationVHgt .ag-root-wrapper-body.ag-layout-auto-height {
  height: calc(100vh - 245px) !important;
}
.customPagination .ag-paging-panel > span {
  display: none !important;
}
.ag-overlay-no-rows-wrapper.ag-layout-auto-height {
  padding-top: 0px !important;
}

.ag-theme-alpine .ag-overlay-no-rows-wrapper .ag-overlay-no-rows-center {
  padding: 15px 40px !important;
  border-radius: 4px !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  border: 1px solid #ffc6c6 !important;
  box-shadow: 0px 1px 4px 1px #ffc6c6 !important;
  /* border: 1px solid #ff7e7e !important; */
  /* box-shadow: 0px 0px 6px 1px rgb(255 0 0 / 70%) !important; */
}

/* MUI alert styles */
.MuiPaper-root.MuiAlert-root {
  padding: 12px 16px !important;
  min-width: 850px !important;
}
.MuiAlert-colorWarning {
  /* background-color: #ffeb3b !important; */
  /* background-color: #ffffb3 !important; */
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid #ffc107 !important;
  box-shadow: 0px 0px 6px 1px rgb(255 235 59 / 80%) !important;
}
.MuiAlert-colorError {
  /* background-color: #f44336 !important; */
  /* background-color: #ffe6e5 !important; */
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid #ff0000 !important;
  box-shadow: 0px 0px 6px 1px rgb(255 0 0 / 70%) !important;
}

.MuiAlert-colorSuccess {
  /* background-color: #4caf50 !important; */
  /* background-color: #d8ffda !important; */
  background-color: #fff !important;
  color: #333 !important;
  border: 1px solid green !important;
  box-shadow: 0px 0px 14px 1px rgb(47 141 41 / 80%) !important;
}
.MuiAlert-colorError .MuiAlert-icon {
  color: #f00 !important;
}
.MuiAlert-colorSuccess .MuiAlert-icon {
  color: green !important;
}
.MuiAlert-colorWarning .MuiAlert-icon {
  color: #ffbb47 !important;
}

.inputJson {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: calc(100vh - 150px);
  padding: 15px 25px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.txtJson textarea {
  height: calc(100vh - 320px) !important;
  overflow: auto !important;
}
.txtJson > div {
  padding: 20px 0px 20px 20px !important;
}
.docSumHgt {
  height: calc(100vh - 160px) !important;
  overflow: auto !important;
}

/* styles for custom table grid start */
.mTableCustom.MuiTableContainer-root {
  border: 1px solid #ddd;
  box-shadow: none !important;
}
.mTableCustom thead th:first-child {
  width: 38% !important;
}
.mTableCustom thead th:not(:first-child) {
  width: 8% !important;
}
.mTableCustom tr.level1 td:first-child {
  width: 37% !important;
}
.mTableCustom tr.level1 td:not(:first-child) {
  width: 8% !important;
}
.mTableCustom tr.level2 td:first-child {
  width: 37% !important;
  border-bottom: 1px solid #f5f5f5 !important;
}
.mTableCustom tr.level2 td:not(:first-child) {
  width: 8% !important;
  border-bottom: 1px solid #f5f5f5 !important;
}
.mTableCustom
  .level0
  .MuiAccordionSummary-root
  + .MuiCollapse-vertical
  .MuiAccordionDetails-root {
  padding: 0px 16px 0px 32px !important;
}
.mTableCustom .level0.MuiTableCell-root {
  border-bottom: 1px solid #ebebeb !important;
}
.mTableCustom .level0 .MuiAccordionSummary-content {
  margin: 0px !important;
}
.mTableCustom .level1 .MuiTableCell-root {
  border-bottom: none !important;
}

.mTableCustom .MuiPaper-root.MuiAccordion-root {
  border: none !important;
}
.mTableCustom .MuiButtonBase-root.MuiAccordionSummary-root {
  background-color: rgba(0, 0, 0, 0.01) !important;
}

/* styles for custom table grid end */

.centerMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 25px !important;
  border-radius: 4px;
  border: 1px solid #ffc6c6 !important;
  box-shadow: 0px 1px 4px 1px #ffc6c6 !important;
  border-bottom-color: #ffc6c6 !important;
  /* border: 1px solid #ff7e7e !important;
  box-shadow: 0px 0px 6px 1px rgb(255 0 0 / 70%) !important; */
}

/* Style by ravi m */

._1Lxpd {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 2rem;
  box-sizing: border-box;
  position: relative;
}

._35l6Q {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

._35l6Q ._1oXyH {
  position: relative;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

canvas {
  max-width: 1024px;
  width: -moz-fit-content;
  width: fit-content;
  height: 100%;
  max-height: 700px;
  -o-object-fit: contain;
  object-fit: contain;
}

canvas:hover {
  cursor: pointer;
}

._2hTXI {
  /* padding: 0.4rem 2rem; */
  border: none;
  border-radius: 0.25rem;
  /* background-color: #20c2ec !important; */
  /* color: #fff; */
  font-weight: 500;
  cursor: pointer;
  color: #333 !important;
  /* font-weight: 500; */
  background-color: #fff !important;
  border: 1px solid #20c2ec !important;
  padding: 7px 8px;
  border-radius: 4px;
  /* width: 100%; */
  transition: 0.4s;
}

._2hTXI:hover {
  color: #fff !important;
  background-color: #20c2ec !important;
}

._2hTXI:disabled {
  background-color: #fff !important;
  cursor: not-allowed !important;
  color: #bdbdbd !important;
}

._2yRPI {
  margin: 0 16px;
  font-size: 1rem;
  color: #000;
  width: 100px;
  display: inline-block;
  text-align: center;
}

._Tn-iv button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background-color: #20c2ec !important;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  z-index: 99;
  padding: 5px 12px;
}

._Tn-iv button:nth-child(1) {
  left: 5%;
}

._Tn-iv button:nth-child(2) {
  right: 5%;
}

._2hsM7 {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 99;
  border: none;
  color: #20c2ec;
  font-size: 1rem;
  cursor: pointer;
  padding: 5px 12px;
  transition: all cubic-bezier(0.075, 0.82, 0.165, 1);
}

._2hsM7 svg {
  width: 20px;
  height: 20px;
}

.highlight-total {
  color: #000;
  background: #fffba8;
  border-radius: 4px;
  width: max-content;
  padding: 6px 16px;
}

.textAreaDynHgt .MuiInputBase-multiline textarea {
  height: calc(100vh - 335px) !important;
  overflow: auto !important;
}

.fileInputLabel {
  border: 1px dashed #a9a9a9;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}
.singleDocument-leftside:hover .fileInputLabel {
  border: 1px solid #20c2ec;
  background-color: #20c2ec !important;
  color: #fff !important;
}
.err-boundary-css {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f9f9f9;
  color: #333;
  padding: 20px;
  box-sizing: border-box;
}
.err-boundary-css .err-heading {
  font-size: 24px;
  margin-bottom: 10px;
  color: #d32f2f;
}

.err-boundary-css .err-content {
  font-size: 16px;
  margin-bottom: 20px;
  color: #555;
}

.err-boundary-css .err-refresh-btn {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #1976d2;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
 
}

